<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.total }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="blue" size="40">
                <v-icon dark>mdi-account-group-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total grossistes
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.notip }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="red" size="40">
                <v-icon dark>mdi-account-cancel-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total sans compte TIP
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.nogifty }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="red" size="40">
                <v-icon dark>mdi-account-cancel-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total sans compte Gifty
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.total_points | toCurrency }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="success" size="40">
                <v-icon dark>mdi-currency-usd</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total des points gagnés
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card :disabled="overlay" :loading="overlay" class="rounded-lg shadow">
            <v-card-title>

              <div>
                <v-text-field v-model="keyword" append-icon="mdi-magnify" class="w-300" clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>


              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="drawer = true"
                         v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>


              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon
                         v-bind="attrs"
                         v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>


                  <v-list-item @click="addItem">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Ajouter un grossiste</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="downloadCanvas">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-download-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Télécharger le canvas</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="[
                                  importDialog = true,
                                  excel_file = '',
                                  $refs.excel_file ?  $refs.excel_file.reset() : false,
                                  info = '',
                                  errors = [],
                                  ExcelErrors = [],
                                  ]">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-upload-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Importer</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="ExportExcel">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-cloud-download-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Exporter</v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && wholesalers.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && wholesalers.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Nom</th>
                    <th>Code</th>
                    <th>Téléphone</th>
                    <th>Type</th>
                    <th>Région</th>
                    <th>Maps</th>
                    <th>Compte Gifty</th>
                    <th>Compte TIP</th>
                    <th>Pts KPI's</th>
                    <th>Pts Codes QR</th>
                    <th>Total Pts</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in wholesalers" :key="item.id">

                    <td>
                      <v-avatar size="38">
                        <v-img v-if="item.photo"
                               :src="FILE_URL + item.photo"></v-img>
                        <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                      </v-avatar>
                    </td>

                    <td>{{ item.name }}</td>

                    <td class="font-weight-medium blue--text">
                      {{ item.code }}
                    </td>

                    <td>{{ item.phone }}</td>

                    <td>{{ item.type }}</td>

                    <td>{{ item.region }}</td>

                    <td>
                      <div class="d-block text-no-wrap">
                        <a :href="'https://www.google.com/maps/search/?api=1&query=' + item.latitude + ',' + item.longitude"
                           class="blue--text text-decoration-none"
                           target="_blank">
                          <v-icon color="blue" dense>mdi-map-marker</v-icon>
                          {{ item.latitude }} - {{ item.longitude }}
                        </a>
                      </div>
                    </td>


                    <td>
                      <v-icon v-if="item.gifty_account" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else color="red">mdi-close-circle</v-icon>
                    </td>

                    <td>
                      <v-icon v-if="item.tip_account" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else color="red">mdi-close-circle</v-icon>
                    </td>

                    <td>
                      <v-chip :disabled="!(item.kpi_points > 0)"
                              color="success" dark
                              small>
                        {{ parseFloat(item.kpi_points) | toCurrency }}
                      </v-chip>
                    </td>

                    <td>
                      <v-chip :disabled="!(item.qr_code_points > 0)"
                              color="success" dark
                              small>
                        {{ parseFloat(item.qr_code_points) | toCurrency }}
                      </v-chip>
                    </td>

                    <td>
                      <v-chip :disabled="!((item.kpi_points + item.qr_code_points) > 0)"
                              color="blue" dark
                              small>
                        {{ parseFloat((item.kpi_points + item.qr_code_points)) | toCurrency }}
                      </v-chip>
                    </td>

                    <td>
                      <v-menu bottom offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="[wholesalerDetails = true,wholesaler=item]">
                            <v-list-item-title>
                              <v-icon dense left>mdi-eye-plus</v-icon>
                              Voir plus
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="editItem(item)">
                            <v-list-item-title>
                              <v-icon dense left>mdi-pencil-outline</v-icon>
                              Modifier
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider v-if="total > 10"/>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="importDialog" width="400">
        <v-card :loading="importBtnLoading">
          <v-card-title>
            Importer
            <v-spacer/>
            <v-icon @click="importDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">

            <v-alert v-if="info" color="warning" text>
              <p>{{ info }}</p>
              <div v-for="(errors,i) in ExcelErrors" :key="i">
                <ul>
                  <li v-for="(err,index) in  errors" :key="index">
                    <strong>Ligne : {{ err.row }}</strong> / {{ err.message }}
                  </li>
                </ul>
              </div>
            </v-alert>

            <v-file-input ref="excel_file"
                          :error-messages="errors.excel_file"
                          accept=".xlsx, .xls"
                          append-icon="mdi-file-excel"
                          dense
                          filled
                          hide-details="auto"
                          label="Fichier excel *"
                          rounded
                          show-size
                          small-chips
                          @change="uploadFile"
                          @click="errors && errors.excel_file ? errors.excel_file = '' : false"/>

          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="importBtnLoading" class="rounded-lg" color="primary" depressed
                   @click="importDatabase">
              <v-icon left>mdi-cloud-download-outline</v-icon>
              Importer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer v-model="drawer"
                           :permanent="drawer" app
                           clipped
                           floating
                           right
                           temporary width="400">
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer ref="FilterDrawer" @filter="getwholesalers"/>
      </v-navigation-drawer>

      <WholesalerForm ref="wholesalerForm"
                      :dialog.sync="wholesalerForm"
                      @get-wholesalers="getwholesalers"/>

      <WholesalerDetails v-if="wholesaler"
                         :dialog.sync="wholesalerDetails"
                         :wholesaler.sync="wholesaler"/>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FilterDrawer from "./FilterDrawer";
import WholesalerForm from "./WholesalerForm.vue";
import WholesalerDetails from "./WholesalerDetails";
import NoResult from "../../components/NoResult";

export default {
  components: {NoResult, FilterDrawer, WholesalerForm, WholesalerDetails},
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      status: true,
      overlay: false,
      loading: false,
      importDialog: false,

      wholesalerForm: false,
      wholesalerDetails: false,

      id: '',
      wholesalers: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      keyword: '',
      keywords: this.$store.state.keyword,
      drawer: false,
      importBtnLoading: false,
      excel_file: '',
      errors: [],
      ExcelErrors: [],
      info: '',
      wholesaler: '',

      //filter
      filter: {
        have_gifty_account: 'all',
        have_tip_account: 'all',
        cities: [],
      },

      stat: {}
    }
  },
  methods: {
    uploadFile(file) {
      this.excel_file = file
    },
    importDatabase() {
      this.info = ''
      this.ExcelErrors = []
      this.importBtnLoading = true
      this.$Progress.start()
      let formData = new FormData()
      formData.append('excel_file', this.excel_file)
      HTTP.post('/wholesalers/import', formData).then((res) => {
        this.importBtnLoading = false
        this.$Progress.finish()
        this.$successMessage = 'Cette base de données a été importé avec succés'
        this.getwholesalers()

        if (res.data.status) {
          this.importDialog = false
          this.excel_file = ''
        } else {
          if (res.data && res.data.errors) {
            this.ExcelErrors = res.data.errors
            this.info = "Une erreur ç'est produit veuillez le corriger :"
          }else {
            this.importDialog = false
          }
        }
      }).catch(err => {
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.importBtnLoading = false
        console.log(err)
      })
    },
    editItem(item) {
      this.wholesalerForm = true
      this.$refs.wholesalerForm.editItem(item)
    },
    addItem() {
      this.wholesalerForm = true
      this.$refs.wholesalerForm.addItem()
    },
    getwholesalers(filter) {
      this.loading = true
      this.drawer = false
      this.$Progress.start()
      this.filter = filter ? filter : this.filter
      HTTP.get('/wholesalers?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          per_page: this.per_page,
          filter: this.filter
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.wholesalers = res.data.data.data.data
        this.stat = res.data.data.stat

        this.pagination.current = res.data.data.data.current_page;
        this.pagination.total = res.data.data.data.last_page;
        this.total = res.data.data.data.total;

      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    onPageChange() {
      this.getwholesalers();
    },
    downloadCanvas() {
      window.location.href = process.env.VUE_APP_FILE_URL + '/wholesalers-canvas.xlsx';
    },
    ExportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/wholesalers?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    refresh() {
      let filter = {
        have_gifty_account: 'all',
        have_tip_account: 'all',
        cities: [],
      }
      this.$refs.FilterDrawer.refresh()
      this.getwholesalers(filter)
    }
  },
  created() {
    this.getwholesalers()
  },
  watch: {
    keyword() {
      this.getwholesalers();
    },
    per_page() {
      this.pagination.current = 1
      this.getwholesalers();
    },
  },
}
</script>

<style scoped>

</style>